import React, { Fragment } from "react";
import PaintingsGallery from "./components/PaintingsGallery";

export const Paintings = () => {
  return (
    <Fragment>
      <section class="jumbotron text-center">
        <div class="container">
          <h1 class="jumbotron-heading">
            Imperfectly Vertical | Painting Gallery
          </h1>
          <p class="lead text-muted">Enjoy the paintings.</p>
        </div>
      </section>
      <PaintingsGallery />
    </Fragment>
  );
};
