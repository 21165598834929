import React from "react";
import ImageGallery from "react-image-gallery";
import "../styles/image-gallery.scss";

// Import photos
// Originals
import photo_bob_dylan from "./../assets/images/drawings/bob_dylan.jpg";
import photo_chess_books from "./../assets/images/drawings/chess_books.jpg";
import photo_dead_mans_hand from "./../assets/images/drawings/dead_mans_hand.jpg";
import photo_eagle from "./../assets/images/drawings/eagle.jpg";
import photo_john_singer_sargent_1 from "./../assets/images/drawings/john_singer_sargent_1.jpg";
import photo_john_singer_sargent_2 from "./../assets/images/drawings/john_singer_sargent_2.jpg";
import photo_john_singer_sargent_3 from "./../assets/images/drawings/john_singer_sargent_3.jpg";
import photo_joni_mitchell from "./../assets/images/drawings/joni_mitchell.jpg";
import photo_kavin_baker from "./../assets/images/drawings/kavin_baker.jpg";
import photo_king_crimson from "./../assets/images/drawings/king_crimson.jpg";
import photo_king_of_spades from "./../assets/images/drawings/king_of_spades.jpg";
import photo_lizard from "./../assets/images/drawings/lizard.jpg";
import photo_mc_escher_ant from "./../assets/images/drawings/mc_escher_ant.jpg";
import photo_mc_escher_face from "./../assets/images/drawings/mc_escher_face.jpg";
import photo_miles_davis from "./../assets/images/drawings/miles_davis.jpg";
import photo_zebra from "./../assets/images/drawings/zebra.jpg";
// Latest work
import photo_dmitri_shostakovich from "./../assets/images/drawings/dmitri_shostakovich.jpg";

const images = [
  {
    original: photo_bob_dylan,
    thumbnail: photo_bob_dylan,
  },
  {
    original: photo_chess_books,
    thumbnail: photo_chess_books,
  },
  {
    original: photo_dead_mans_hand,
    thumbnail: photo_dead_mans_hand,
  },
  {
    original: photo_eagle,
    thumbnail: photo_eagle,
  },
  {
    original: photo_john_singer_sargent_1,
    thumbnail: photo_john_singer_sargent_1,
  },
  {
    original: photo_john_singer_sargent_2,
    thumbnail: photo_john_singer_sargent_2,
  },
  {
    original: photo_john_singer_sargent_3,
    thumbnail: photo_john_singer_sargent_3,
  },
  {
    original: photo_joni_mitchell,
    thumbnail: photo_joni_mitchell,
  },
  {
    original: photo_kavin_baker,
    thumbnail: photo_kavin_baker,
  },
  {
    original: photo_king_crimson,
    thumbnail: photo_king_crimson,
  },
  {
    original: photo_king_of_spades,
    thumbnail: photo_king_of_spades,
  },
  {
    original: photo_lizard,
    thumbnail: photo_lizard,
  },
  {
    original: photo_mc_escher_ant,
    thumbnail: photo_mc_escher_ant,
  },
  {
    original: photo_mc_escher_face,
    thumbnail: photo_mc_escher_face,
  },
  {
    original: photo_miles_davis,
    thumbnail: photo_miles_davis,
  },
  {
    original: photo_zebra,
    thumbnail: photo_zebra,
  },
  {
    original: photo_dmitri_shostakovich,
    thumbnail: photo_dmitri_shostakovich,
  },
];

class DrawingGallery extends React.Component {
  render() {
    return (
      <ImageGallery
        items={images}
        lazyLoad={true}
        showThumbnails={false}
        showIndex={true}
      />
    );
  }
}

export default DrawingGallery;
