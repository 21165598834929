import React from "react";
import { Jumbotron, Button, Container } from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";

const JumbotronHeader = (props) => {
  return (
    <div>
      <Jumbotron>
        <Container>
          <h1>Imperfectly Vertical</h1>
          <p className="lead">
            A gallery of artworks by the artist Imperfectly Vertical
          </p>
          <hr className="my-2" />
          <p>Begin by exploring the gallery:</p>
          <p className="lead">
            <Button
              tag={RRNavLink}
              color="secondary"
              size="lg"
              exact
              to="/Paintings"
            >
              View Paintings
            </Button>{" "}
            <Button
              tag={RRNavLink}
              color="secondary"
              size="lg"
              exact
              to="/Drawings"
            >
              View Drawings
            </Button>
          </p>
        </Container>
      </Jumbotron>
    </div>
  );
};

export default JumbotronHeader;
