import React, { Fragment } from "react";
import DrawingsGallery from "./components/DrawingsGallery";

export const Drawings = () => {
  return (
    <Fragment>
      <section class="jumbotron text-center">
        <div class="container">
          <h1 class="jumbotron-heading">
            Imperfectly Vertical | Drawing Gallery
          </h1>
          <p class="lead text-muted">Enjoy the drawings.</p>
        </div>
      </section>
      <DrawingsGallery />
    </Fragment>
  );
};
