import React from "react";

export const About = () => {
  const boxStyle = {
    width: "90%",
    height: "500px",
    borderRadius: "21px 21px 0 0",
  };

  return (
    <div className="bg-dark mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
      <div className="my-3 py-3">
        <h3 className="display-5">Who is Imperfectly Vertical?</h3>
      </div>
      <div className="bg-light shadow-sm mx-auto" style={boxStyle}>
        <p>It is a mystery.</p>
      </div>
      <p></p>
    </div>
  );
};
