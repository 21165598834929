import React, { Component } from "react";

export default class AppFooter extends Component {
  render() {
    return (
      <>
        <hr />
        <footer class="page-footer font-small blue">
          <div class="footer-copyright text-center py-3">
            {" "}
            &copy; {new Date().getFullYear()} Copyright:{" "}
            <a href="https://www.ImperfectlyVertical.com">
              {" "}
              ImperfectlyVertical.com{" "}
            </a>
          </div>
        </footer>
      </>
    );
  }
}
