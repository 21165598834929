import React, { Fragment } from "react";
import JumbotronHeader from "./components/JumbotronHeader";
import LatestWork from "./components/LatestWork";

export const Home = () => {
  return (
    <Fragment>
      <JumbotronHeader />
      <LatestWork />
    </Fragment>
  );
};
