import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Pages
import { Home } from "./Home";
import { About } from "./About";
import { Paintings } from "./Paintings";
import { Drawings } from "./Drawings";
import { Contact } from "./Contact";

// Components
import AppNavbar from "./components/AppNavbar";
import { Layout } from "./components/Layout";
import AppFooter from "./components/AppFooter.jsx";
import "./App.css";

function App() {
  return (
    <React.Fragment>
      <Router>
        <AppNavbar />
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/paintings" component={Paintings} />
            <Route exact path="/drawings" component={Drawings} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route component={Home} />
          </Switch>
        </Layout>
        <AppFooter />
      </Router>
    </React.Fragment>
  );
}

export default App;
