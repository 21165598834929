import React from "react";
import ImageGallery from "react-image-gallery";
import "../styles/image-gallery.scss";

// Import photos
// Originals
import photo_abbey_road_abstract from "./../assets/images/paintings/abbey_road_abstract.jpg";
import photo_austerity from "./../assets/images/paintings/austerity.jpg";
import photo_escher_pattern_bird from "./../assets/images/paintings/escher_pattern_bird.jpg";
import photo_jack_daniels from "./../assets/images/paintings/jack_daniels.jpg";
import photo_matchstick from "./../assets/images/paintings/matchstick.jpg";
import photo_owl from "./../assets/images/paintings/owl.jpg";
import photo_penguins from "./../assets/images/paintings/penguins.jpg";
import photo_portrait_of_a_man_in_a_red_turban from "./../assets/images/paintings/portrait_of_a_man_in_a_red_turban.jpg";
import photo_ram from "./../assets/images/paintings/ram.jpg";
import photo_skull from "./../assets/images/paintings/skull.jpg";
import photo_the_guitarist from "./../assets/images/paintings/the_guitarist.jpg";
import photo_van_gogh from "./../assets/images/paintings/van_gogh.jpg";
// Latest work
import photo_girl_with_a_pearl_earring from "./../assets/images/paintings/girl_with_a_pearl_earring.jpg";

const images = [
  {
    original: photo_abbey_road_abstract,
    thumbnail: photo_abbey_road_abstract,
  },
  {
    original: photo_austerity,
    thumbnail: photo_austerity,
  },
  {
    original: photo_escher_pattern_bird,
    thumbnail: photo_escher_pattern_bird,
  },
  {
    original: photo_jack_daniels,
    thumbnail: photo_jack_daniels,
  },
  {
    original: photo_matchstick,
    thumbnail: photo_matchstick,
  },
  {
    original: photo_owl,
    thumbnail: photo_owl,
  },
  {
    original: photo_penguins,
    thumbnail: photo_penguins,
  },
  {
    original: photo_portrait_of_a_man_in_a_red_turban,
    thumbnail: photo_portrait_of_a_man_in_a_red_turban,
  },
  {
    original: photo_ram,
    thumbnail: photo_ram,
  },
  {
    original: photo_skull,
    thumbnail: photo_skull,
  },
  {
    original: photo_the_guitarist,
    thumbnail: photo_the_guitarist,
  },
  {
    original: photo_van_gogh,
    thumbnail: photo_van_gogh,
  },
  {
    original: photo_girl_with_a_pearl_earring,
    thumbnail: photo_girl_with_a_pearl_earring,
  },
];

class PaintingGallery extends React.Component {
  render() {
    return (
      <ImageGallery
        items={images}
        lazyLoad={true}
        showThumbnails={false}
        showIndex={true}
      />
    );
  }
}

export default PaintingGallery;
